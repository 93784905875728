import './modules/modals';
import './modules/forms';
import './modules/masks';
import './modules/spinner';
import './modules/scroll';
import './modules/menus';
import './modules/tabs';
import './modules/galleries';
import './modules/rotators';
import './modules/spoilers';
import './modules/filters';
import ClosableTeaser from './modules/widgets/closable-teaser';


// Init closable teaser
// --------------------

const $preHeader = $('.js-pre-header');
if ($preHeader.length) {
  const storageKey = `TEASER_HIDE_DATE_${$preHeader.data('storage-id')}`;
  const teaser = new ClosableTeaser({
    selector: '.js-pre-header',
    closeSelector: '.js-close-pre-header',
    storageKey,
    daysToHide: 7,
  });
}


$(window).on('scroll', function(){
  let w = $(window).width();
  let scrollTop = $(document).scrollTop();

  if (w <= 768){
    if (scrollTop >= 185) {
      $('.header-nav').addClass('floated');
      $('body').addClass('floated-header');
    } else {
      $('.header-nav').removeClass('floated');
      $('body').removeClass('floated-header');
    }
  } else {
    $('.header-nav').removeClass('floated');
    $('body').removeClass('floated-header');
  }
});



//Стоимость за час работы
var zpArray = [480, 570, 680, 770];
//по умолчанию, со знанием иностранного, за пределами Москвы, оба параметра

var k1 = 0.13,    //Сумма НДФЛ
    k2 = 0.22,    //ПФР
    k31 = 0.029,  //ФСС
    k32 = 0.018,
    k4 = 0.002,   //ФСС (травматизм)
    k51 = 0.051,  //ФФОМС
    k52 = 0,
    k6 = 0.001,   //комиссия банка
    k7 = 2500;    //услуги


$(document).ready(function() {

  //Меню-гамбургер
  $(".header-nav .hamburger").click(function() { console.log(1);
    if ($(this).hasClass("is-active")) {
      $(this).removeClass("is-active");
      $(".header-nav nav").css("height", 0);
    } else {
      $(this).addClass("is-active");
      $(".header-nav nav").css("height", $(".header-nav nav ul").outerHeight());
    }
  });

  $(window).resize(function() {
    if ($(".header-nav .hamburger").hasClass("is-active")) {
      $(".header-nav nav").css("height", $(".header-nav nav ul").outerHeight());
    }
  });

  //Эффект появления / скрытия фанкибокс
  $.fancybox.defaults.animationEffect = "zoom-in-out";


  //Якорные ссылки
  $(".-anchor").click(function() {
    $("html,body").animate({scrollTop: $($(this).attr("href")).offset().top}, "slow");
  });

  //Поля ввода
  $(".ui-order__inp input, .ui-order__inp textarea").change(function() {
    if ($(this).val() != "") {
      $(this).closest(".ui-order__inp").addClass("-active");
    } else {
      $(this).closest(".ui-order__inp").removeClass("-active");
    }
  });

  //Карта
  if (typeof ymaps !== "undefined") {
    if ($("#map").length > 0) {
      ymaps.ready(function() {
        init("map");
      });
    }
    if ($("#contact-map").length > 0) {
      ymaps.ready(function() {
        init("contact-map");
      });
    }
  }

  var myMap;
  var myPlacemark;

  function init(id){
    var item = $("#"+id);
    var itemX = parseFloat(item.data("x"));
    var itemY = parseFloat(item.data("y"));
    var center = [itemY, itemX];
    var hint = item.data("hint");
    var balloon = item.data("balloon");

    myMap = new ymaps.Map(id, {
      center: center,
      zoom: 16,
      controls: ["zoomControl"] //["zoomControl", "searchControl", "typeSelector",  "fullscreenControl", "routeButtonControl"]
    });
    myMap.behaviors.disable("scrollZoom");

    myPlacemark = new ymaps.Placemark(center,
      {
        hintContent: hint,
        balloonContent: balloon
      }
    );
    myMap.geoObjects.add(myPlacemark);
    myMap.behaviors.disable("scrollZoom");
    if ($(window).width() < 992) {
      myMap.behaviors.disable("drag");
    }
  }


  //------------------- Главная страниц -------------------

  // Карусель первого экрана
  if ($(".ui-top__slider .swiper-slide").length > 1) {
    var swiperMainTop = new Swiper (".ui-top__slider", {
      slidesPerView: 1,
      slidesPerColumn: 1,
      autoHeight: true,
      loop: true,
      pagination: {
        el: ".ui-top__slider .swiper-pagination",
        clickable: true
      }
    });
  }

  // Карусель мероприятий
  var swiperMainEvent = new Swiper (".ui-event__slider", {
    slidesPerView: 1,
    slidesPerColumn: 1,
    autoHeight: true,
    loop: true,
    pagination: {
      el: ".ui-event .swiper-pagination",
      clickable: true
    },
    navigation: {
      prevEl: ".ui-event__nav .-prev",
      nextEl: ".ui-event__nav .-next"
    },
  });

  // Карусель персонала
  var swiperPersonal = new Swiper (".ui-personal__slider", {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 40,
    autoHeight: true,
    loop: true,
    navigation: {
      prevEl: "#ui-personal-prev",
      nextEl: "#ui-personal-next"
    },
    breakpoints: {
      576: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      991: {
        slidesPerView: 2
      },
      1199: {
        slidesPerView: 3
      }
    }
  });

  // Карусель нас рекоммендуют
  var swiperRecommendation = new Swiper (".ui-recommendation__slider", {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 40,
    autoHeight: true,
    loop: true,
    navigation: {
      prevEl: "#ui-recommendation-prev",
      nextEl: "#ui-recommendation-next"
    },
    breakpoints: {
      576: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      991: {
        slidesPerView: 2
      },
      1199: {
        slidesPerView: 3
      }
    }
  });

  // Карусель клиентов
  var swiperMainClient = new Swiper (".ui-client__slider", {
    slidesPerView: 5,
    slidesPerColumn: 1,
    // spaceBetween: 10,
    autoHeight: true,
    loop: true,
    navigation: {
      prevEl: ".ui-client__nav .-prev",
      nextEl: ".ui-client__nav .-next"
    },
    breakpoints: {
      576: {
        slidesPerView: 1
      },
      // 767: {
      // 	slidesPerView: 3
      // },
      991: {
        slidesPerView: 3
      },
      1199: {
        slidesPerView: 4
      }
    }
  });

  //Карусель Для кого подходит
  var swiperMainWhom = new Swiper (".ui-whom__slider", {
    slidesPerView: 1,
    slidesPerColumn: 1,
    autoHeight: true,
    loop: false,
    allowTouchMove: false
  });

  //Закладки Для кого подходит
  $(".ui-whom__tabs span").click(function() {
    if (!$(this).hasClass("-active")) {
      $(".ui-whom__tabs span").removeClass("-active");
      $(this).addClass("-active");
      var index = $(".ui-whom__tabs span").index($(this));
      swiperMainWhom.slideTo(index);
    }
  });

  //Карусель команды для мобилки
  mainTeamSlider();
  $(window).resize(mainTeamSlider);

  var swiperMainTeam;
  function mainTeamSlider() {
    if ($(window).width() < 568) {
      if (swiperMainTeam) {
        return;
      } else {
        swiperMainTeam = new Swiper(".ui-team__slider", {
          slidesPerView: 1,
          loop: true,
          autoplay : 3000,
          pagination: {
            el: ".ui-team__slider .swiper-pagination",
            clickable: true
          }
        });
      }
    } else if (swiperMainTeam) {
      swiperMainTeam.destroy();
      swiperMainTeam = undefined;
    }
  }


  //------------------- Калькулятор -------------------
  $(".-digital").keydown(digital);

  //Калькулятор стоимости
  if ($(".calc-cost").length>0) {

    calcCost();

    //Количество работников
    var workersSlider = $("#calc-cost-workers-slider");
    workersSlider.slider({
      orientation: "horizontal",
      range: "min",
      min: parseInt(workersSlider.data("min")),
      max: parseInt(workersSlider.data("max")),
      value: parseInt($("#calc-cost-workers").val()),
      animate: true,
      slide: workersSlide,
      change: workersSlide
    });

    $("#calc-cost-workers").keyup(function() {
      if ($(this).val() != "") {
        var workersAmount = parseInt($(this).val());
        workersSlider.slider("value", workersAmount);
        calcCost();
      }
    });


    function workersSlide() {
      $("#calc-cost-workers").val(workersSlider.slider("value"));
      calcCost();
    }

    // var workersTotal = 10;
    // var workersActive = parseInt($("#calc-cost-workers").val());
    // for (var i = 1; i <= workersTotal; i++) {
    // 	$(".calc-cost__workers").append("<span data-index='"+i+"'></span>");
    // }
    // workersSelect(workersActive);

    // $(".calc-cost__workers span").mouseenter(function() {
    // 	var index = parseInt($(this).data("index"));
    // 	workersSelect(index);
    // });

    // $(".calc-cost__workers").mouseleave(function() {
    // 	var index = parseInt($("#calc-cost-workers").val());
    // 	workersSelect(index);
    // });

    // $(".calc-cost__workers span").click(function() {
    // 	var index = parseInt($(this).data("index"));
    // 	$("#calc-cost-workers").val(index);
    // 	workersSelect(index);
    // 	calcCost();
    // })

    // $("#calc-cost-workers").keyup(function() {
    // 	var workersAmount = parseInt($(this).val()) || 1;
    // 	workersSelect(workersAmount);
    // 	calcCost();
    // });

    // function workersSelect(amount) {
    // 	$(".calc-cost__workers span").addClass("-active");
    // 	$(".calc-cost__workers span:nth-child(n+"+(amount+1)+")").removeClass("-active");
    // }

    //Количество рабочих часов
    var hoursSlider = $("#calc-cost-hours-slider");
    hoursSlider.slider({
      orientation: "horizontal",
      range: "min",
      min: parseInt(hoursSlider.data("min")),
      max: parseInt(hoursSlider.data("max")),
      value: parseInt($("#calc-cost-hours").val()),
      animate: true,
      slide: hoursSlide,
      change: hoursSlide
    });

    $("#calc-cost-hours").keyup(function() {
      if ($(this).val() != "") {
        var hoursAmount = parseInt($(this).val());
        hoursSlider.slider("value", hoursAmount);
        calcCost();
      }
    });

    function hoursSlide() {
      $("#calc-cost-hours").val(hoursSlider.slider("value"));
      calcCost();
    }

    $("#calc-cost-workers, #calc-cost-hours").change(function() {
      if ($(this).val() == "") {
        $(this).val($(this).closest(".calc-cost__wrap").find(".calc-cost__slider").data("min"));
        calcCost();
      }
    });

    //Дата начала работы
    $.datepicker.setDefaults(
      {
        closeText: 'Закрыть',
        prevText: '',
        currentText: 'Сегодня',
        monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь',
          'Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
        monthNamesShort: ['Янв','Фев','Мар','Апр','Май','Июн',
          'Июл','Авг','Сен','Окт','Ноя','Дек'],
        dayNames: ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
        dayNamesShort: ['вск','пнд','втр','срд','чтв','птн','сбт'],
        dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
        weekHeader: 'Не',
        dateFormat: 'd MM, yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
      });
    $("#calc-cost-date").datepicker();

    //Чекбоксы
    $("#calc-cost-eng, #calc-cost-out").change(calcCost);

    function calcCost() {
      var workers = parseInt($("#calc-cost-workers").val());
      var hours = parseInt($("#calc-cost-hours").val());

      var zpIndex = 0;

      if ($("#calc-cost-eng").prop("checked")) {
        if ($("#calc-cost-out").prop("checked")) {
          zpIndex = 3;
        } else {
          zpIndex = 1;
        }
      } else if ($("#calc-cost-out").prop("checked")) {
        zpIndex = 2;
      }

      var zpVal = zpArray[zpIndex];
      var totalVal = workers * hours * zpVal;

      $("#calc-cost-hour .-val").text(dSpace(Math.floor(zpVal)));
      $("#calc-cost-total .-val").text(dSpace(Math.floor(totalVal)));

    }
  }

  //Калькулятор затрат за месяц
  if ($(".calc-spending").length>0) {

    $(window).resize(function() {
      if ($(".calc-spending__result").hasClass("-active")) {
        $(".calc-spending__result").height($(".calc-spending__resultwrap").outerHeight());
      }
    });

    $(".calc-spending__hide button").click(function() {
      $(".calc-spending__result").removeClass("-active").height(0);
    });
    $("#calc-spending-btn").click(function(e) {
      e.preventDefault();
      //Разворачиваем таблицу
      $(".calc-spending__result").addClass("-active").height($(".calc-spending__resultwrap").outerHeight());

      //Гражданство
      var citizen = $("#calc-spending-ru").prop("checked");

      //Заработная плата сотрудника начисленная
      var zpw = parseInt($("#calc-spending-zpw").val())||20000;
      $("#calc-spending-zpw").val(zpw);
      $("#calc-spending-zp").text(dSpace(zpw)+" р");

      //Сумма НДФЛ
      var ndfl = zpw * k1;
      $("#calc-spending-ndfl").text(dSpace(Math.floor(ndfl))+" р");

      //Заработная плата сотрудника после вычета НДФЛ
      var zpNdfl = zpw - ndfl;
      $("#calc-spending-zp-ndfl").text(dSpace(Math.floor(zpNdfl))+" р");

      //ПФР
      var pfr = zpw * k2;
      $("#calc-spending-pfr").text(dSpace(Math.floor(pfr))+" р");

      //ФСС
      var fss = (citizen) ? zpw * k31 : zpw * k32;
      $("#calc-spending-fss").text(dSpace(Math.floor(fss))+" р");

      //ФСС (травматизм)
      var fssT = zpw * k4;
      $("#calc-spending-fss-t").text(dSpace(Math.floor(fssT))+" р");

      //ФФОМС
      var ffoms = (citizen) ? zpw * k51 : zpw * k52;
      $("#calc-spending-ffoms").text(dSpace(Math.floor(ffoms))+" р");

      //Комиссия банка
      var kom = zpNdfl * k6;
      $("#calc-spending-kom").text(dSpace(Math.floor(kom))+" р");

      //Услуги
      var serv = k7;
      $("#calc-spending-serv").text(dSpace(Math.floor(serv))+" р");

      //Итого без учета НДС 20%
      var totalNoNds = zpw + pfr + fss + fssT + ffoms + kom + serv;
      $("#calc-spending-total-nonds").text(dSpace(Math.floor(totalNoNds))+" р");

      //Сумма НДС 20%
      var nds = totalNoNds * 0.20;
      $("#calc-spending-nds").text(dSpace(Math.floor(nds))+" р");

      //Итого с учетом НДС 20%
      var totalNds = totalNoNds + nds;
      $("#calc-spending-total-nds").text(dSpace(Math.floor(totalNds))+" р");

      //Возврат заработной платы Работнику
      var zpRe = zpNdfl;
      $("#calc-spending-zp-re").text(dSpace(Math.floor(zpRe))+" р");

      //Возмещение НДС Заказчика услуги
      var nsdCustomer = nds;
      $("#calc-spending-nsd-customer").text(dSpace(Math.floor(nsdCustomer))+" р");

      //Уменьшение налогооблагаемой прибыли у Заказчика
      var spendingProfit = totalNds;
      $("#calc-spending-profit").text(dSpace(Math.floor(spendingProfit))+" р");

      //Итого затратная часть
      var total = totalNoNds - zpNdfl;
      $("#calc-spending-total").text(dSpace(Math.floor(total))+" р");
    });
  }



  //Подсказки
  $(".calc-info").click(function() {
    if ($(window).width() > 575) {
      $(".calc-info").removeClass("-active");
      $(this).addClass("-active");
    } else {
      $.fancybox.open($(this).siblings(".calc-hint").text());
    }
  });

  $(document).click(function(e) {
    var container1 = $(".calc-hint");
    var container2 = $(".calc-info");
    if ((!container1.is(e.target) && container1.has(e.target).length === 0)&&(!container2.is(e.target) && container2.has(e.target).length === 0)) {
      container2.removeClass("-active");
    }
  });


  function digital(e) {
    if ((e.keyCode < 48 || (e.keyCode > 57 && e.keyCode < 96) || e.keyCode > 105) && e.keyCode != 8 && e.keyCode != 9 && e.keyCode != 37 && e.keyCode != 39 && e.keyCode != 46) e.preventDefault();
  }

  function dSpace (val) {
    return val.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1\u00A0');
  }


  // Выбор услуги по умолчанию в форме
  $('#popup-order-calc-1').on('click', function(){
    console.log('#popup-order #OrderService_service_type_1');
    $('#popup-order input').prop('checked',false);
    $('#popup-order #OrderService_service_type_1').trigger('click');
  });

  $('#popup-order-calc-2').on('click', function() {
    console.log('#popup-order #OrderService_service_type_0');
    $('#popup-order input').prop('checked',false);
    $('#popup-order #OrderService_service_type_0').trigger('click');
  });

  if ($('.ui-order #OrderService_service_type_0').length > 0){
    console.log('.ui-order #OrderService_service_type_0');
    $('.ui-order #OrderService_service_type_0').trigger('click');
  }


  // Костыль для корректной демонстрации верстки шаблонов нового подраздела
  if ($('#vremennye_sekretari').length || $('#promo_personal').length) {
    $('.article-section .text-container').removeClass('container');
  }


  $('html').removeClass('no-js').addClass('has-js');

  window.addEventListener("load", function(){
    $('html').addClass('loaded');
  });

});
